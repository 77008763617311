<template>
    <div class="nav" :class="mobileNav?'nav-mobile':''">
		<Sign :signbar='signbar' @getbar='getbar()' ref="sign"></Sign>
	  	<el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" :router="true">
		  <el-menu-item index="/">首页</el-menu-item>
		  <el-menu-item index="/type">分类</el-menu-item>
		  <el-menu-item index="/link">友链</el-menu-item>
		  <el-menu-item @click='searchIn()'>搜索</el-menu-item>
		  <el-menu-item index="/diary">随心记</el-menu-item>
		  <el-menu-item index="/whisper">留言板</el-menu-item>
		  <el-submenu index="7" class="more">
		    <template slot="title">更多</template>
		    <el-menu-item index="/nav">导航</el-menu-item>
		    <el-menu-item index="/image">图片</el-menu-item>
			<el-menu-item index="/package">画集</el-menu-item>
			<el-menu-item index="/tag">标签</el-menu-item>
			<el-menu-item index="/me">设置</el-menu-item>
		    <!-- <el-menu-item index="/qc">世界</el-menu-item> -->
			<el-menu-item index="/manual">群手册</el-menu-item>
		  </el-submenu>
	  	</el-menu>
		<el-menu
		      :default-active="activeIndex2"
		      class="el-menu-vertical-demo"
		      @open="handleOpen"
		      text-color="#fff"
		      active-text-color="#ffd04b"
			  :router="true">
			  <div class="mobile-me-cover">
			  	<div class="mobile-me-avatar" :class="login?'':'nologin'"><img :src="user.avatar"></div>
			  	<div v-text="login?user.username:'未登录'"></div>
				<el-upload
				  class="upload-demo"
				  action=""
				  :show-file-list="false"
				  :auto-upload="false"
				  :on-change="update"
				  v-if="!updateAvatarTime">
				  <el-button  type="primary">点击上传</el-button>
				</el-upload>
			  </div>
		      <el-submenu index="1">
		        <template slot="title">
		          <i class="el-icon-sunny"></i>
		          <span>Navigation</span>
		        </template>
		        <el-menu-item-group>
		          <el-menu-item index="/" ><i class="el-icon-s-home"></i>首页</el-menu-item>
		          <el-menu-item index="/type"><i class="el-icon-s-flag"></i>分类</el-menu-item>
		          <el-menu-item index="/link"><i class="el-icon-s-promotion"></i>友链</el-menu-item>
				  <el-menu-item index="/diary"><i class="el-icon-document"></i>随心记</el-menu-item>
				  <el-menu-item index="/whisper"><i class="el-icon-edit-outline"></i>留言板</el-menu-item>
		        </el-menu-item-group>
		      </el-submenu>
			  <el-submenu index="2">
			    <template slot="title">
			      <i class="el-icon-moon"></i>
			      <span>More</span>
			    </template>
			    <el-menu-item-group>
			      <el-menu-item index="/nav" ><i class="el-icon-coffee"></i>导航</el-menu-item>
			      <el-menu-item index="/image"><i class="el-icon-ice-tea"></i>图片</el-menu-item>
				  <el-menu-item index="/package"><i class="el-icon-food"></i>画集</el-menu-item>
				  <el-menu-item index="/tag"><i class="el-icon-burger"></i>标签</el-menu-item>
			      <!-- <el-menu-item index="/qc"><i class="el-icon-ice-drink"></i>世界</el-menu-item> -->
				  <el-menu-item index="/manual"><i class="el-icon-hot-water"></i>群手册</el-menu-item>
			    </el-menu-item-group>
			  </el-submenu>
			  <el-submenu index="3">
			    <template slot="title">
			      <i class="el-icon-setting"></i>
			      <span>User</span>
			    </template>
			    <el-menu-item-group>
			      <el-menu-item @click="stopMove(1)" v-if='!login'><i class="el-icon-lollipop"></i>登录</el-menu-item>
			      <el-menu-item @click="stopMove(0)" v-if='!login'><i class="el-icon-ice-cream-round"></i>注册</el-menu-item>
				  <el-menu-item @click="toMe()" v-if='login'><i class="el-icon-s-custom"></i>设置</el-menu-item>
				  <el-menu-item @click="logout()" v-if='login'><i class="el-icon-heavy-rain"></i>退出登录</el-menu-item>
			    </el-menu-item-group>
			  </el-submenu>
		    </el-menu>
			<div class="nav-mobile-img" :class="mobileNav?'nav-mobile-img-open':''" @click="more()">
				<img src="/img/kasuie/more.webp" width="4em" alt="more">
			</div>
			<div class="nav-mobile-close" :class="mobileNav?'nav-mobile-close-show':''" @click="more()"></div>
		<ul class="user-loader">
			<li class="user-theme"><a href="javascript:void(0);" @click="switchTheme"><img :src="!darkTheme?'/img/kasuie/sun.png':'/img/kasuie/night.png'" alt="theme"></a></li>
			<li @mouseover="hover = true" @mouseleave="hover = false"><a href="javascript:void(0);" @click="login?toMe():stopMove(1)" class="loginbar-show"><img :src="user.avatar"></a>
				<ul class="sub-menu" :class="{active: hover}" @mouseover="hover = true" @mouseleave="hover = false">
					<div class="box-body">
						<div class="loginbar" v-if="!login">
							<div class="my-loginbar">
								<a href="javascript:void(0);" @click="stopMove(1)" title="登录喵">
									<div><img src="/img/kasuie/login.png"></div>
									<div>登录</div>
								</a>
								<a href="javascript:void(0);" @click="stopMove(0)" title="注册喵">
									<div><img src="/img/kasuie/regist.png"></div>
									<div>注册</div>
								</a>
							</div>
							<p class="separator">快速登录</p>
							<div class="social-loginbar">
								<a href="javascript:;" @mouseover="hoverqq = true" @mouseleave="hoverqq = false"><div><img :src="hoverqq==true?'/img/kasuie/icon_qq_.png':'/img/kasuie/icon_qq.png'"></div></a>
								<a href="javascript:;" @mouseover="hoverwx = true" @mouseleave="hoverwx = false"><div><img :src="hoverwx==true?'/img/kasuie/icon_weixin_.png':'/img/kasuie/icon_weixin.png'"></div></a>
							</div>
						</div>
						<MyCard :user="user" v-if="login" ref="myCard"></MyCard>
					</div>
				</ul>
			</li>
		</ul>
		<Search ref='search' @switchTheme=switchTheme :darkTheme='darkTheme'></Search>
	</div>
</template>

<script>
import Sign from '@/components/Sign.vue'
import MyCard from '@/components/MyCard.vue'
import Search from '@/components/Search.vue'
import Cropper from '@/components/Cropper.vue'

  export default {
	name: 'Nav',
	components: {
		Sign,
		MyCard,
		Search,
		Cropper
	},
    data() {
      return {
        activeIndex: '1',
        activeIndex2: '1',
		temp: 0,
		login: localStorage.getItem("login"),
		hover: false,
		darkTheme: false, 
		signbar: false,
		hoverqq: false,
		hoverwx: false,
		mobileNav: false,
		updateAvatarTime: true,
		user: {
			uid: '',
			username: '',
			avatar: '',
			level: ''
		}
      };
    },
    methods: {
		switchTheme(){
			this.darkTheme = !this.darkTheme
			this.darkTheme?this.$cookies.set("darkTheme", 1 , "1m"):this.$cookies.set("darkTheme", 0 , "1m")
		},
		getData() {
			if (this.login) {
				this.user = this.$store.getters.getUser
				if(!this.$cookies.isKey("updateAvatarTime")){
					this.updateAvatarTime = false
				}
				if(this.user.level>1){
					this.updateAvatarTime = false
				}
			} else{
				this.user.avatar = '/img/kasuie/sign_in.png'
			}
		},
		toMe(){
			this.$router.push({ path: '/me'})
		},
		stopMove(val){
			this.signbar = !this.signbar
			this.switchBar(val)
			if(this.mobileNav){
				this.more()
			}
			this.unmove()
		},
		unmove(){
			// let m = function(e){
			// 	e.preventDefault()
			// }
			document.body.style.overflow='hidden'
			// document.body.addEventListener('touchmove',m,{ passive: false})
		},
		move(){
			// let m = function(){
			// 	window.event.returnValue = true
			// }
			document.body.style.overflow=''
			// document.body.addEventListener('touchmove',m)
		},
		update(val){
			this.$refs.myCard.changeUpload(val)
		},
		menu() {
			let nav= document.getElementsByClassName('nav')[0]
		    let scroll = document.documentElement.scrollTop || document.body.scrollTop
			if ((scroll-this.temp)>20) {
				nav.classList.remove('nav-scroll-up')
				nav.classList.add('nav-scroll-down')
			} else if((scroll-this.temp)<-20){
				nav.classList.remove('nav-scroll-down')
				nav.classList.add('nav-scroll-up')
			}
			if(scroll==0){
				nav.classname='nav'
			}else if(scroll>400){
				nav.classList.add('nav-scroll-bg')
			}else{
				nav.classList.remove('nav-scroll-bg')
			}
		    this.temp = scroll
		},
		more(){
			let more = document.getElementsByClassName('el-menu-vertical-demo')[0]
			this.mobileNav?more.classList.remove('nav-mobile-isactive'):more.classList.add('nav-mobile-isactive')
			this.mobileNav?this.move():this.unmove()
			this.mobileNav = !this.mobileNav
		},
		getbar(val){
			this.signbar = val
		},
		logout(){
			this.$refs.myCard.logout()
		},
		switchBar(val){
			this.$refs.sign.switchBar(val)
		},
		searchIn(){
			this.$refs.search.searchIn()
		},
		handleSelect(key) {
			this.activeIndex2 = key
		},
		handleOpen(key) {
			this.activeIndex2 = key
		},
		handleClose(key, keyPath) {
			console.log(key, keyPath);
		}
    },
	mounted() {
	    window.addEventListener('scroll', this.menu)
		// this.handleOpen('1')
		this.handleOpen('3')
		// this.handleOpen('3')
	},
	watch: {
		signbar(){
			if(!this.signbar){
				this.move()
			}
		},
		darkTheme(){
			let app = document.getElementById('app')
			let body = document.getElementsByTagName('body')[0]
			let root = document.querySelector(':root');
			if(this.darkTheme){
				app.classList.add('darkApp')
				body.classList.add('body-dark-theme')
				root.setAttribute('style','--theme-color: white;--theme-background-color: #171212')
			}else{
				app.classList.remove('darkApp')
				body.classList.remove('body-dark-theme')
				root.setAttribute('style','--theme-color: black;--theme-background-color: rgba(255,255,255,.6)')
			}
		}
	},
	created(){
		this.getData()
		this.move()
		this.handleOpen(this.activeIndex2,['1'])
		if(this.$cookies.isKey("darkTheme")){
			if(parseInt(this.$cookies.get("darkTheme"))==1){
				this.darkTheme = true
			}
		}
	}
  }
</script>
<style type="text/css">
.nav{
	position: fixed;
	width: 100%;
	height: 60px;
	top: 0;
	z-index: 11;
	transition: all .5s ease-in-out;
}
.nav-mobile{
	height: 100%;
	background-color: rgba(0,0,0,.5);
}
.nav-scroll-down{
	animation: navhidden .5s ease-in-out forwards;
}
.nav-scroll-up{
	top: -60px;
	animation: navshow .5s .3s ease-in-out forwards;
}
.nav-scroll-bg{
	background-color: rgba(0,0,0,.3);
}
@keyframes navhidden{
	from{
		top: 0;
	}
	to{
		top: -60px;
	}
}
@keyframes navshow{
	from{
		top: -60px;
	}
	to{
		top: 0;
	}
}
.el-menu-demo{
	position: absolute;
	display: inline-block;
	background-color: unset;
	z-index: 2;
	left: 20%;
}
.el-menu.el-menu--horizontal{
	border: none;
}
.el-menu--horizontal>.el-menu-item::after{
	position: absolute;
	content: '';
	height: 2px;
	left: 0;
	width: 0;
	display: table;
	transition: width .4s ease-in-out;
}
.el-menu--popup>.el-menu-item{
	margin: 2px 0;
}
.el-menu--popup>.el-menu-item::after{
	position: absolute;
	content: '';
	height: 2px;
	left: 0;
	width: 0;
	display: table;
	transition: width .4s ease-in-out;
}
.el-menu--horizontal>.el-menu-item:hover::after{
	width: 100%;
	background-color: white;
}
.el-menu--popup>.el-menu-item:hover::after{
	width: 100%;
	background-color: white;
}
.el-menu--popup>.el-menu-item:hover{
	color: white !important;
}
.el-menu--popup{
	padding: 0;
	background-color: rgba(0,0,0,0.25);
}
.el-menu--horizontal .el-menu .el-menu-item, .el-menu--horizontal .el-menu .el-submenu__title{
	color: white;
	background-color: rgba(0,0,0,0.25);
	backdrop-filter: saturate(3) blur(16px);
}
.el-menu-vertical-demo{
	position: absolute;
	height: 100%;
	width: 70%;
	left: -70%;
	overflow-y: auto;
	backdrop-filter: saturate(5) blur(10px);
	transition: all .3s ease-in-out;
	background-image: url(/img/bg_nav_m.webp);
	background-size: cover;
}
.el-submenu__title{
	background-color: unset !important;
}
.el-menu-vertical-demo>li:nth-last-child(1){
	margin-bottom: 5em;
}
.nav-mobile-isactive{
	transform: translate(100%,0);
	/* left: 0; */
}
.nav-mobile-isactive::-webkit-scrollbar{
	width: 7px;
}
.nav-mobile-isactive::-webkit-scrollbar-thumb{
	border-radius: 10px;
	background: rgba(255,255,255,.7);
}
.nav-mobile-isactive .el-submenu__title i.el-submenu__icon-arrow {
	transform: scale(1.5);
	color: white;
}
.nav-mobile-img{
	position: absolute;
	top: .3em;
	left: -1em;
	transition: all ease-in-out .3s;
	display: none;
}
.nav-mobile-img img{
	width: 4em;
	height: 4em;
}
.nav-mobile-close{
	position: absolute;
	right: 0;
	transform: translate(0,0);
	width: 30%;
	height: 100%;
	display: none;
}
/* .nav-mobile-img-fixed{
	position: fixed;
	right: 0;
	left: calc(70% - 1em);
} */
.el-menu-vertical-demo .el-menu-item, .el-submenu__title{
	user-select: none;
	text-align: start;
}
.el-menu--horizontal>.el-menu-item,.el-menu--horizontal>.el-menu-item.is-active{
	color: white;
}
.el-menu--horizontal>.el-submenu .el-submenu__title{
	color: white;
}
.el-menu--horizontal>.el-submenu .el-submenu__title:hover{
	color: black;
}
.more .el-submenu__title i{
	color: white;
}
.el-menu--horizontal>.el-submenu .el-submenu__title:hover i{
	color: black;
}
.el-menu--horizontal>.el-menu-item:not(.is-disabled):focus, .el-menu--horizontal>.el-menu-item:not(.is-disabled):hover, .el-menu--horizontal>.el-submenu .el-submenu__title:hover,.el-menu--horizontal>.el-submenu.is-active .el-submenu__title,.el-submenu.is-active .el-submenu__title{
	background-color: unset;
	color: white;
}
.el-menu--horizontal>.el-menu-item.is-active{
	border-bottom: unset;
}
.loginbar-show img,.user-theme img{
	width: 2em;
	height: 2em;
	border-radius: 50%;
	vertical-align: middle;
	margin-left: .5em;
}
.loginbar-show img{
	margin-left: 1.5em;
}
.user-theme img{
	width: 2.5em;
	height: 2.5em;
}
.user-loader{
	position: absolute;
	right: 20%;
	list-style: none;
}
.user-loader li{
	position: relative;
	text-align: end;
	height: 60px;
	line-height: 60px;
	display: inline-block;
	z-index: 2;
}
.user-loader .user-theme{
	position: absolute;
	right: 3em;
	z-index: 3;
}
.sub-menu{
	text-align: center;
	background-color: rgba(255,255,255,.9);
	width: 280px;
	height: auto;
	min-width: 100px;
	padding: 10px 5px;
	display: none;
	opacity: 0;
	border-radius: 1.5em;
	backdrop-filter: saturate(5) blur(10px);
	box-shadow: -4px 4px 8px 8px rgb(0 0 0 / 20%);
	transition: all .5s ease-in-out;
}
.loginbar{
	padding: 1em;
}
.my-loginbar a,.social-loginbar a{
	text-decoration: none;
	display: inline-block;
	padding: 2em;
}
.my-loginbar div{
	overflow: hidden;
	line-height: 2em;
}
.my-loginbar div img{
	border-radius: 50%;
	transition: all .3s ease-in-out;
}
.my-loginbar div img:hover{
	transform: scale(1.1);
}
.social-loginbar div{
	width: 32px;
	height: 32px;
	line-height: 32px;
	border-radius: 100%;
}
.separator:before,.separator::after{
	content: "";
	background: grey;
	width: 20%;
	vertical-align: middle;
	height: 1px;
	display: inline-block;
	margin: 0 1em;
}
.social-loginbar a:nth-child(1):hover div{
	background-color: rgb(48,165,221);
}
.social-loginbar a:nth-child(2):hover div{
	background-color: rgb(70,187,54);
}
.active{
	display: block;
	opacity: 1;
}
.mobile-me-cover{
	margin: 3em 0;
	width: 100%;
}
.mobile-me-avatar img{
	width: 10em;
	border-radius: 50%;
	transition: all .5s ease-in-out;
	animation: light 4s ease-in-out infinite;
}
.mobile-me-avatar img:hover{
	position: relative;
	transform: translateY(-.75em)
}
.mobile-me-cover div:nth-child(2){
	font-size: 30px;
	color: #29bdff;
	font-family: STSong;
	text-shadow: 3px 3px 3px skyblue;
}
.nologin img{
	width: unset;
}
.el-menu-item:focus, .el-menu-item:hover{
	background-color: #43586fba;
}
.el-menu{
	background-color: unset;
	border-right: none;
}
.darkApp .sub-menu{
	background-color: rgba(255,255,255,.1);
}
@media only screen and (max-width: 1000px){
	.el-menu-demo{
		left: 10%;
	}
	.user-loader{
		right: 15%;
	}
}
@media only screen and (max-width: 770px){
	.el-menu-demo{
		display: none;
	}
	.nav-mobile-img{
		display: block;
	}
	.nav-mobile-img-open{
		left: calc(70% - 1em);
	}
	.user-loader{
		display: none;
	}
	.nav-mobile-close-show{
		z-index: 3;
		display: block;
	}
}
</style>
