<template>
	<div id="tab-sign-in">
		<div class="tip">
			<div>登录</div>
			<a href="javascript:void(0)" @click="switchBar(0)">没有帐号？立即<span>注册</span></a>
		</div>
		<div id="sign-in">
			<div class="sign-content">
				<el-form :model="user" ref="user" :rules="rules" status-icon label-width="0" class="demo-ruleForm" autocomplete="off">
				  <el-form-item @mouseover.native="focusu = true" @mouseleave.native="focusu = false" prop="username">
				    <el-input v-model="user.username" autocomplete="off" placeholder="Username or e-mail"></el-input>
					<i class="line-form" :class="{line: focusu}"></i>
				  </el-form-item>
				  <el-form-item @mouseover.native="focusp = true" @mouseleave.native="focusp = false" prop="password">
				    <el-input type="password" v-model="user.password" autocomplete="off" placeholder="Your password"></el-input>
					<i class="line-form" :class="{line: focusp}"></i>
				  </el-form-item>
				  <el-form-item>
				    <el-button type="primary" @click="submitForm('user')" round icon='el-icon-ice-cream-round'>登录</el-button>
				  </el-form-item>
				</el-form>
				<p class="separator">快捷登录</p>
				<div class="sign-social">
					<a href="javascript:;"><div><img src="/img/kasuie/icon_qq.png" id='qqLoginBtn'></div></a>
					<a href="javascript:;"><div><img src="/img/kasuie/icon_weixin.png"></div></a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	inject:['reload'],
	name: 'Login',
	data() {
		return {
			focusu: false,
			focusp: false,
			user: {
				username: null,
				password: null,
				code: null,
			},
			rules: {
				username: [
				    { required: true, message: '请输入用户名', trigger: 'blur' },
				],
			    password: [
					{ required: true, message: '请输入密码', trigger: 'blur' }
			    ],
			}
		}
	},
	methods: {
		signIn(){
			let that = this
			this.axios.post('signIn',
				that.user
			).then(function(results){
				let data = results.data
				if(data.login){
					that.user.password = null
					let msg = '登录成功'
					that.$store.commit('setLogin',data)
					that.openL(msg)
					setTimeout(function() {
						that.reload()
					}, 1000)
				}else{
					that.$notify.error({
						title: '警告',
						message: '密码或用户名错误，登录失败!'
					});
				}
			},function(err){
				console.log(err)
			})
		},
		submitForm(formName) {
		    this.$refs[formName].validate((valid) => {
				if (valid) {
					this.signIn()
				} else {
					return false;
				}
		    });
		},
		switchBar(val){
			this.$emit('switchBar',val)
		},
		openL(msg){
			this.$emit('openLogin',msg)
		},
	},
}
</script>

<style>
.tip{
	width: 90%;
	text-align: start;
	margin: 1em auto;
}
.tip div{
	color: white;
	font-size: 2em;
	font-weight: 600;
}
.tip a{
	color: grey;
	text-decoration: none;
}
.tip>a>span{
	color: #61b6d0;
	text-decoration: underline;
}
.sign-content .el-input__inner{
	outline: 0!important;
	border: none;
	display: block;
	color: powderblue;
	width: 100%;
	padding: 1em 2em .4em .3em;
	opacity: .8;
	transition: .3s;
	background: 0 0!important;
}
.sign-content .el-form-item__error{
	padding-left: .4em;
}
.sign-content .el-input{
	width: 100%;
}
.sign-content .el-button{
	background: var(--g-01);
	width: 70%;
}
.sign-content .el-form-item{
    width: 90%;
    margin: 22px auto;
}
.line-form, .line-form:before {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    width: 100%;
    transition: .7s;
    background: rgba(114, 114, 114, 0.1);
}
.line-form:before{
	content: '';
	width: 0;
	background: rgba(62,188,226,.8);
}
.line::before{
	content: '';
	width: 100%;
	background: rgba(62,188,226,.8);
}
.separator{
	font-size: .9em;
	color: #636469;
}
.sign-content .separator:before,.sign-content .separator::after{
	content: "";
	background: rgba(114, 114, 114, 0.1);
	width: 20%;
	vertical-align: middle;
	height: 1px;
	display: inline-block;
	margin: 0 1em;
}
.sign-content .sign-social a div{
	height: 32px;
	width: 32px;
	line-height: 32px;
	border-radius: 100%;
	display: inline-block;
	margin: 1em;
}
@media screen and (max-width: 770px) {
	.bar-body{
		width: 75% !important;
		top: 10% !important;
	}
}
</style>
