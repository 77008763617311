<template>
	<div class="signbar" :class="{'bar-show': signbar }" @click="sendbar()">
		<div class="bar-body" @click.stop="hh()">
			<span class="bar-close"><i class="el-icon-close" @click="sendbar()"></i></span>
			<component :is="viewBar" @switchBar='switchBar' @openLogin='openLogin' @openRegist='openRegist'></component>
		</div>
	</div>
</template>

<script>
import Login from '@/components/Login.vue'
import Regist from '@/components/Regist.vue'
export default {
	inject:['reload'],
	name: 'Sign',
	components:{
		Login,
		Regist,
	},
	props: ['signbar'],
	data() {
		return {
			reqbar: null,
			view: ['Login','Regist'],
			indexBar: 0,
		}
	},
	methods: {
		sendbar(){
			this.$emit('getbar',this.reqbar)
		},
		switchBar(index){
			if(index==0){
				this.indexBar = 1
			}else if(index==1){
				this.indexBar = 0
			}
		},
		hh(){
			
		},
		openLogin(msg){
			this.$notify({
				title: '成功',
				message: msg,
				type: 'success'
		    });
			this.sendbar()
		},
		openRegist(msg){
			this.$notify({
				title: '成功',
				message: msg,
				type: 'success'
		    });
			this.sendbar()
			setTimeout(() => {
				this.$confirm('是否现在要前往设置自定义头像验证邮箱?', '提示', {
				  confirmButtonText: '前往设置',
				  cancelButtonText: '暂不设置',
				  type: 'warning'
				}).then(() => {
					this.$router.push({ path: '/me'})
				}).catch(() => {
					this.reload()
				})
			},1000)
		},
	},
	computed: {
		viewBar(){
			return this.view[this.indexBar]
		}
	},
	watch: {
		indexBar(){
			this.viewBar
		},
	},
}
</script>

<style>
.signbar{
	position: fixed;
	background-color: rgba(0,0,0,.7);
	width: 100%;
	height: 100%;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: none;
	opacity: 0;
	transition: all .5s ease-in-out;
	z-index: 99;
}
.bar-show{
	display: block;
	opacity: 1;
}
.bar-body{
	position: relative;
	width: 25%;
	height: auto;
	padding: 1.5em;
	background-color: #FFFFFF;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	background: rgba(55, 56, 58, 0.8);
	backdrop-filter: saturate(5) blur(20px);
	margin: 25% auto;
	margin-top: 15%;
	border-radius: 1.5em;
}
.bar-close i{
	position: absolute;
	right: 2em;
	cursor: pointer;
	color: rgba(255,255,255,.4);
	transition: all .5s ease-in-out;
	transform: rotate(0) scale(1.4);
}
.bar-close i:hover{
	color: rgba(255,255,255,.7);
	transform: rotate(360deg) scale(1.5);
}
</style>
