<template>
	<div id="tab-sign-up">
		<div class="tip">
			<div>注册</div>
			<a href="javascript:void(0)" @click="switchBar(1)">已有帐号，立即<span>登录</span></a>
		</div>
		<div id="sign-up">
			<div class="sign-content">
				<el-form :model="user" ref="user" :rules="rules" status-icon label-width="0" class="demo-ruleForm">
				  <el-form-item @mouseover.native="focusu = true" @mouseleave.native="focusu = false" prop="username">
				    <el-input v-model="user.username" autocomplete="off" placeholder="Your username" @input='checkN()'></el-input>
					<i class="line-form" :class="{line: focusu}"></i>
				  </el-form-item>
				  <el-form-item @mouseover.native="focusp = true" @mouseleave.native="focusp = false" prop="password">
				    <el-input type="password" v-model="user.password" autocomplete="off" placeholder="Your password"></el-input>
					<i class="line-form" :class="{line: focusp}"></i>
				  </el-form-item>
				  <el-form-item @mouseover.native="focuscp = true" @mouseleave.native="focuscp = false" prop="comfirmpwd">
				    <el-input type="password" v-model="user.comfirmpwd" autocomplete="off" placeholder="Comfirm password"></el-input>
				  	<i class="line-form" :class="{line: focuscp}"></i>
				  </el-form-item>
				  <el-form-item @mouseover.native="focuse = true" @mouseleave.native="focuse = false" prop="email">
				    <el-input v-model="user.email" autocomplete="off" placeholder="Your email"></el-input>
				  	<i class="line-form" :class="{line: focuse}"></i>
				  </el-form-item>
				  <el-form-item>
				    <el-button type="primary" @click="submitForm('user')" round icon='el-icon-sugar'>注册</el-button>
				  </el-form-item>
				</el-form>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Regist',
	data() {
		var validateName = (rule, value, callback) => {
			if (value != ''){
				if(!this.checkName){
					callback(new Error('用户名已存在'));
				}
				callback();
			}else{
				callback(new Error('请输入昵称'));
			}
		};
		var validatePass = (rule, value, callback) => {
		    if (value === '') {
		        callback(new Error('请输入密码'));
		    } else {
		        if (this.user.comfirmpwd !== '') {
		            this.$refs.user.validateField('comfirmpwd');
		        }
		        callback();
		    }
		};
		var validatePass2 = (rule, value, callback) => {
		    if (value === '') {
		        callback(new Error('请再次输入密码'));
		    } else if (value !== this.user.password) {
		        callback(new Error('两次输入密码不一致!'));
		    } else {
		        callback();
		    }
		};
		var validateEmail = (rule,value,callback) => {
			if(value != ''){
				let rgx = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
				if(value.match(rgx)==null){
					callback(new Error('请输入正确的邮箱'));
				}
				callback();
			}else{
				callback(new Error('请输入邮箱'));
			}
		};
		return {
			focusu: false,
			focuse: false,
			focusp: false,
			focuscp: false,
			checkName: true,
			user: {
				username: null,
				password: null,
				comfirmpwd: null,
				email: null,
			},
			rules: {
				username: [
					{ required: true, message: '请输入昵称', trigger: 'blur' },
					{ validator: validateName, trigger: 'blur' },
					{ min: 3, max: 15, message: '长度在 3 到 15 个字符', trigger: 'blur' }
				],
			    password: [
					{ required: true, message: '请输入密码', trigger: 'blur' },
					{ validator: validatePass, trigger: 'blur' },
					{ min: 5, max: 32, message: '长度在 5 到 32 个字符', trigger: 'blur' }
			    ],
			    comfirmpwd: [
					{ required: true, message: '请再次输入密码', trigger: 'blur' },
			        { validator: validatePass2, trigger: 'blur' }
			    ],
				email: [
				    { required: true, message: '请输入邮箱', trigger: 'blur' },
					{ validator: validateEmail, trigger: 'blur' },
				],
			}
		}
	},
	methods: {
		signUp(){
			let that = this
			this.axios.post('signUp',
				that.user
			).then(function(results){
				if(results.data){
					that.setLogin()
					let msg = '注册成功'
					that.openR(msg)
				}else{
					that.$notify.error({
						title: '警告',
						message: '注册失败'
					});
				}
			},function(err){
				console.log(err)
			})
		},
		setLogin(){
			let that = this
			this.axios.post('signIn',
				that.user
			).then(function(results){
				let data = results.data
				if(data.login){
					that.user.password = null
					that.$store.commit('setLogin',data)
				}else{
					that.$notify.error({
						title: '警告',
						message: '自动登录失败，请手动登录试试，还不行联系博主解决'
					});
				}
			},function(err){
				console.log(err)
			})
		},
		submitForm(formName) {
		    this.$refs[formName].validate((valid) => {
				if (valid) {
					this.signUp()
				} else {
					return false;
				}
		    });
		},
		switchBar(val){
			this.$emit('switchBar',val)
		},
		checkN(){
			let that = this
			if(that.user.username!=null&&that.user.username!=''){
				let url = 'checkName?username='+that.user.username
				this.axios.get(url).then(function(results){
					that.checkName = results.data
				},function(err){
					console.log(err)
				})
			}
		},
		openR(msg){
			this.$emit('openRegist',msg)
		}
	}
}
</script>

<style>
.tip>a>span{
	color: #61b6d0;
	text-decoration: underline;
	margin-left: .2em;
}
</style>
