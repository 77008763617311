<template>
	<div class="my-card">
		<el-upload
		  class="upload-demo"
		  drag
		  action=""
		  multiple
		  :show-file-list="false"
		  :auto-upload="false"
		  :on-change='changeUpload'
		  v-if="!updateAvatarTime">
		  <i class="el-icon-upload"></i>
		  <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
		  <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过2M</div>
		</el-upload>
		<div class="card-top">
			<div><img v-lazy="userInfo.avatar" alt="avatar"></div>
			<div><h2 v-text="userInfo.username"></h2></div>
		</div>
		<div class="card-body">
			<p><span>邮箱：</span><span v-text="userInfo.email"></span></p>
			<p><span>注册时间：</span><span v-text="$moment(userInfo.createTime).format('YYYY-MM-DD')"></span></p>
		</div>
		<div class="card-footer">
			<el-button type="danger" round @click='logout'>退出登录</el-button>
		</div>
		<Cropper ref="cropper" :uid='userInfo.uid'></Cropper>
	</div>
</template>

<script>
import Cropper from '@/components/Cropper.vue'
export default {
	inject:['reload'],
	name: 'MyCard',
	components: {
		Cropper
	},
	props: ['user'],
	data() {
		return {
			userInfo: '',
			updateAvatarTime: true,
		}
	},
	methods: {
		getUser() {
			this.userInfo = this.$store.getters.getUser
			if(this.userInfo.level>1){
				this.updateAvatarTime = false
			}
		},
		logout(){
			this.$confirm('此操作将退出登录, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',}).then(() => {
					this.$store.commit('logout')
					this.$message({
			        type: 'success',
			        message: '登出成功',
				});
					setTimeout(() => {
						this.reload()
					}, 1000)
				}).catch(() => {
			      this.$message({
			        type: 'info',
			        message: '已取消登出'
			    });          
			});	
		},
		changeUpload(val){
			this.$refs.cropper.changeUpload(val)
		}
	},
	created(){
		this.getUser()
		if(!this.$cookies.isKey("updateAvatarTime")){
			this.updateAvatarTime = false
		}
	}
}
</script>

<style>
.my-card{
	padding: 1.5em 1.5em 1em;
}
.card-top{
	width: 100%;
	text-align: start;
}
.card-top>div:nth-child(1){
	display: inline-block;
	width: 6em;
}
.card-top>div:nth-child(2){
	display: inline-block;
	text-align: center;
	width: 50%;
}
.card-top div img{
	width: 6em;
	vertical-align: middle;
	border-radius: 50%;
}
.card-top div h2 span{
	font-size: .7em;
}
.card-body p{
	position: relative;
	text-align: end;
	border-bottom: 1px solid rgba(0,0,0,.5);
}
.card-body p span:nth-child(1){
	position: absolute;
	left: 1em;
}
.card-body p span:nth-child(2){
	padding-right: 1em;
}
.card-body,.card-footer{
	margin-top: 2em;
}
.el-upload-dragger{
	width: unset;
	height: unset;
}
.el-upload {
	width: 100%;
}
.el-upload-dragger .el-icon-upload{
	margin: 20px 0 0;
}
.el-upload-dragger .el-upload__text{
	height: 20px;
	line-height: 0px;
}
.el-upload__tip{
	margin-top: 0;
	height: 20px;
	line-height: 20px;
}
@media only screen and (max-width: 770px){
	.el-message-box{
		width: 90%;
	}
}
</style>
