<template>
	<div class="cropper-all">
		<el-dialog title="头像剪裁" :visible.sync="dialogVisible" class="cropper-user-avatar" append-to-body>
		    <div class="cropper-content">
		        <div class="cropper" style="text-align:center">
				<el-row>
				<el-col :span="12" style="height: 300px;">
		        <vueCropper
		            ref="cropper"
		            :img="option.img"
		            :outputSize="option.size"
		            :outputType="option.outputType"
		            :info="true"
		            :full="option.full"
		            :canMove="option.canMove"
		            :canMoveBox="option.canMoveBox"
		            :original="option.original"
		            :autoCrop="option.autoCrop"
					:autoCropWidth="option.autoCropWidth"
					:autoCropHeight="option.autoCropHeight"
		            :fixed="option.fixed"
		            :fixedNumber="option.fixedNumber"
		            :centerBox="option.centerBox"
		            :infoTrue="option.infoTrue"
		            :fixedBox="option.fixedBox"
					@realTime="realTime"
					@imgLoad="imgLoad"
		          ></vueCropper>
				  <div class="items-btn">
				  	<el-tooltip class="item" effect="dark" content="向左旋转" placement="top">
				  	    <el-button size="small" @click="turnLeft"><i class="el-icon-refresh-left"></i></el-button>
				  	</el-tooltip>
				  	<el-tooltip class="item" effect="dark" content="向右旋转" placement="top">
				  	    <el-button size="small" @click="turnRight"><i class="el-icon-refresh-right"></i></el-button>
				  	</el-tooltip>
				  	<el-tooltip class="item" effect="dark" content="放大" placement="top">
				  	    <el-button size="small" @click="changeScale(1)"><i class="el-icon-plus"></i></el-button>
				  	</el-tooltip>
				  	<el-tooltip class="item" effect="dark" content="缩小" placement="top">
				  	    <el-button size="small" @click="changeScale(-1)"><i class="el-icon-minus"></i></el-button>
				  	</el-tooltip>
				  </div>
				  </el-col>
				    <el-col :span="12" style="height: 200px;">
				        <div class="upload-preview">
				             <img :src="previews.url" :style="previews.img" v-show="previews.url"/>
				        </div>
				    </el-col>
				</el-row>
				</div>
		    </div>
		    <div slot="footer" class="dialog-footer">
		        <el-button @click="dialogVisible = false">取 消</el-button>
		        <el-button type="primary" @click="finish" :loading="loading">确认</el-button>
		    </div>
		</el-dialog>
	</div>
</template>

<script>
export default {
  name: 'Cropper',
  data() {
    return {
      dialogVisible: false,
      option: {
        img: '', // 裁剪图片的地址
        info: true, // 裁剪框的大小信息
        outputSize: 1, // 裁剪生成图片的质量
        outputType: 'png', // 裁剪生成图片的格式
        canScale: false, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        autoCropWidth: 200, // 默认生成截图框宽度
        autoCropHeight: 200, // 默认生成截图框高度
        fixedBox: true, // 固定截图框大小 不允许改变
        fixed: true, // 是否开启截图框宽高固定比例
        fixedNumber: [1, 1], // 截图框的宽高比例
        full: true, // 是否输出原图比例的截图
        canMoveBox: false, // 截图框能否拖动
        original: false, // 上传图片按照原始比例渲染
        centerBox: false, // 截图框是否被限制在图片里面
        infoTrue: true // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
      },
      picsList: [],  //页面显示的数组
      // 防止重复提交
      loading: false,
	  previews: {},
    }
  },
  props: ['uid'],
  methods: {
    // 上传按钮   限制图片大小
    changeUpload(file) {
      const isLt3M = file.size / 1024 / 1024 < 2
      if (!isLt3M) {
		  this.$notify.error({
		  	title: '警告',
		  	message: '上传文件大小不能超过 2MB!'
		  });
        return false
      }
      this.fileinfo = file
	  const isDWG = file.name.split('.')
	  const format = isDWG[isDWG.length - 1]	  
	  if (format !="png" && format !="jpg") {
		  this.$notify.error({
		  	title: '警告',
		  	message: '上传文件只能是 png或jpg 格式!'
		  });
		  return false
	  }
	  this.dialogVisible = true;
	  var reader = new FileReader()
	  reader.readAsDataURL(file.raw)
	  reader.onload = () => {
		  this.option.img = reader.result
	  }
    },
    finish() {
      this.$refs.cropper.getCropBlob((data) => {
		var formData = new FormData();
		formData.append("file", data, this.fileinfo.name);
		formData.append('uid',this.uid)
        this.loading = true
		this.uploadImg(formData)
      })
    },
	uploadImg(formData){
		let that = this
		this.axios.post('userAvatarUpload',formData,{
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		}).then(function(results){
			if(results.data=='false'){
				that.$notify.error({
					title: '警告',
					message: '修改头像失败'
				});
			}else if(results.data.indexOf('频繁')!=-1){
				that.$notify.error({
					title: '警告',
					message: results.data
				});
			}else{
				that.$store.commit('updateAvatar',results.data)
				that.$cookies.set("updateAvatarTime", that.uid , "3d")
				that.$notify({
					title: '成功',
					message: '修改头像成功！',
					type: 'success'
				});
			}
			that.cancleCropper()
		},function(err){
			console.log(err);
		})
	},
	turnLeft() {
	  this.$refs.cropper.rotateLeft();
	},
	turnRight() {
	  this.$refs.cropper.rotateRight();
	},
	cancleCropper() {//取消截图
	  this.$refs.cropper.clearCrop()
	  this.dialogVisible = false;
	},
	changeScale(num){
	    num = num || 1; 
	    this.$refs.cropper.changeScale(num); 
	},
	imgLoad (msg) {
	    console.log(msg)
	},
	realTime(data) { // 实时预览函数 
	    this.previews = data 
	},
  }
}
</script>

<style>
.cropper-content {
    .cropper {
        width: auto;
        height: 300px;
    }
}
.el-dialog{
	width: 840px;
}
.el-dialog__body{
	width: 800px;
}
.el-dialog__header{
    border-bottom: 1px solid #eaeaea;
}
.upload-preview {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  position: absolute;
  top: 50%;
  transform: translate(50%, -50%);
  width: 200px;
  height: 200px;
  border-radius: 50%;
  box-shadow: 0 0 4px #eaeaea;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
  }
}
.items-btn{
  display: flex;
  padding-top: 20px;
}
.items-btn i{
	position: relative;
	font-size: 12px;
}
@media screen and (max-width: 770px) {
	.cropper-user-avatar .el-dialog{
		width: 500px
	}
}
</style>
