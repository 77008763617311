<template>
	<div class="search">
		<span class="search-img" @click="searchIn()"><img src="/img/kasuie/search.png" width="2em"></span>
		<span class="theme-mobile-img" @click="switchT()"><img :src="!darkTheme?'/img/kasuie/sun.png':'/img/kasuie/night.png'" width="32px" alt="theme"></span>
		<div class="search-content" :class="search?'search-content-show':''" @click="searchIn()">
			<div class="search-body" :class="search?'search-body-show':''" @click.stop="hh()">
				<el-autocomplete
				  v-model="article"
				  :fetch-suggestions="querySearchAsync"
				  placeholder="Search projects..."
				  @select="handleSelect"
				  suffix-icon="el-icon-search">
				</el-autocomplete>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Search',
	data() {
		return {
			queryResults: null,
			article: '',
			timeout:  null,
			search: false
		}
	},
	props: ['darkTheme'],
	methods: {
		switchT(){
			this.$emit('switchTheme')
		},
		searchIn(){
			if(this.search){
				this.move()
			}else{
				this.unmove()
			}
			this.search = !this.search
		},
		articles(){
			let that = this
			this.$axios.post('articles').then(function(results){
				that.queryResults = results.data
			},function(err){
				console.log(err)
			})
		},
		hh(){
			
		},
		move(){
			// let m = function(e){
			// 	e.preventDefault()
			// }
			document.body.style.overflow=''
			// document.addEventListener('touchmove',m,{ passive: false})
		},
		unmove(){
			// let m = function(e){
			// 	e.preventDefault()
			// }
			document.body.style.overflow='hidden'
			// document.addEventListener('touchmove',m,{ passive: false})
		},
		 querySearchAsync(queryString, cb) {
			 let queryResults = this.queryResults;
			 let results = queryString ? queryResults.filter(this.createStateFilter(queryString)) : queryResults;
			 clearTimeout(this.timeout);
			 this.timeout = setTimeout(() => {
				 for (let s of results) {
				 	s['value']=s.title
				 }
				 cb(results);
			}, 3000 * Math.random());
		},
		createStateFilter(queryString) {
			return (article) => {
				return (article.content.toLowerCase().indexOf(queryString.toLowerCase()) != -1);
		    };
		},
		handleSelect(item) {
			window.location.href="/article/"+item.aid
		}
	},
	mounted() {
		this.articles()
	}
}
</script>

<style>
.search-img{
	position: absolute;
	display: none;
	top: 1.8em;
	right: 1em;
}
.theme-mobile-img{
	position: absolute;
	display: none;
	top: 1.5em;
	right: 3.5em;
}
.search-img img{
	width: 2em;
}
.search-content{
	position: fixed;
	width: 0;
	height: 0;
}
.search-body{
	position: fixed;
	top: -10%;
	transform: translate(0,-10%);
	bottom: 0;
	left: 0;
	right: 0;
	height: 0;
	transition: all .3s ease-in-out;
}
.search-body-show{
	height: 0;
	top: 10%;
	transform: translate(0,10%);
}
.search-content-show{
	height: 100%;
	width: 100%;
	background-color: rgba(0,0,0,0.8);
	z-index: 11;
}
.el-autocomplete{
	width: 85%;
}
.el-autocomplete-suggestion{
	border: 1px solid #506898;
	background: rgba(55, 56, 58, 0.8);
	backdrop-filter: saturate(5) blur(20px);
}
.el-autocomplete-suggestion li{
	color: white;
}
.el-autocomplete-suggestion li:hover{
	background-color: #f59898;
}
@media screen and (max-width: 770px) {
	.search-img,.theme-mobile-img{
		display: inline-block;
	}
	
}
</style>
